import { Component } from '@angular/core';
import { RoleId } from '../../models/role.model';
import { ToastService } from '../../services/toast.service';
import { PermissionsService } from '../../services/permissions.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent {
  constructor(
    private permissionsService: PermissionsService,
    private toastService: ToastService,
    private router: Router
  ) {
    if (this.permissionsService.user) {
      this.redirectByRole(this.permissionsService.user.roleIds);
    } else {
      this.router.navigate(['/']);
    }
  }

  private async redirectByRole(roleIds: RoleId[]): Promise<void> {
    if (roleIds) {
      if (
        roleIds.includes(RoleId.SUPERADMIN) ||
        roleIds.includes(RoleId.ADMIN) ||
        roleIds.includes(RoleId.ORDER_MANAGER) ||
        roleIds.includes(RoleId.RETAIL_MANAGER)
      ) {
        await this.router.navigate(['/orders/']);
      } else if (roleIds.includes(RoleId.CUSTOMER_SERVICE_MANAGER) || roleIds.includes(RoleId.CUSTOMER_SERVICE)) {
        await this.router.navigate(['/customer-service/']);
      } else if (roleIds.includes(RoleId.ORDER_ASSISTANT)) {
        await this.router.navigate(['/needs-note-review/']);
      } else if (roleIds.includes(RoleId.PRINT_MANAGER)) {
        await this.router.navigate(['/print/']);
      } else if (roleIds.includes(RoleId.SCATTERER)) {
        await this.router.navigate(['/scatter/']);
      } else if (roleIds.includes(RoleId.SORTER)) {
        await this.router.navigate(['/sorting/']);
      } else if (roleIds.includes(RoleId.SHIPPING_MANAGER) || roleIds.includes(RoleId.SHIPPER)) {
        await this.router.navigate(['/shipping/']);
      } else if (roleIds.includes(RoleId.INHOUSE_DESIGNER)) {
        await this.router.navigate(['/designs/']);
      } else if (roleIds.includes(RoleId.OUTHOUSE_REVIEWER)) {
        await this.router.navigate(['/outhouse-reviewer/']);
      } else if (roleIds.includes(RoleId.MID_TIER_DESIGNER)) {
        await this.router.navigate(['/mid-tier-designer/']);
      } else if (roleIds.includes(RoleId.CROPPER)) {
        await this.router.navigate(['/cropper/']);
      } else if (roleIds.includes(RoleId.OUTHOUSE_DESIGNER) || roleIds.includes(RoleId.MID_TIER_DESIGNER)) {
        await this.router.navigate(['/outhouse-designer/']);
      } else if (roleIds.includes(RoleId.STICKER_DESIGNER)) {
        await this.router.navigate(['/sticker-designer']);
      } else if (roleIds.includes(RoleId.STICKER_REVIEWER)) {
        await this.router.navigate(['/sticker-reviewer']);
      } else if (roleIds.includes(RoleId.HR)) {
        await this.router.navigate(['/users']);
      } else if (roleIds.includes(RoleId.AMAZON_UPLOADER)) {
        await this.router.navigate(['/amazon']);
      } else if (roleIds.includes(RoleId.BOOTH_DESIGNER)) {
        await this.router.navigate(['/booth-designer/']);
      } else if (roleIds.includes(RoleId.LABELER)) {
        await this.router.navigate(['/labeler/']);
      } else if (roleIds.includes(RoleId.SHIPPINGEASY_UPLOADER)) {
        await this.router.navigate(['/shippingeasy/']);
      } else if (roleIds.includes(RoleId.BULK_DESIGNER)) {
        await this.router.navigate(['/bulk/designs']);
      } else if (roleIds.includes(RoleId.SUBLIMATION_DESIGNER)) {
        await this.router.navigate(['/sublimation-designer']);
      } else if (roleIds.includes(RoleId.SUBLIMATION_REVIEWER)) {
        await this.router.navigate(['/sublimation-reviewer']);
      } else if (roleIds.includes(RoleId.FREE_SAMPLE_APPROVER)) {
        await this.router.navigate(['/bulk/conceptor-queue']);
      } else if (roleIds.includes(RoleId.FREE_SAMPLE_DESIGNER)) {
        await this.router.navigate(['/bulk/design-queue']);
      } else {
        this.toastService.error('An error occurred - make sure you have a role.', null);
        await this.permissionsService.logout();
      }
    } else {
      await this.permissionsService.logout();
    }
  }
}
