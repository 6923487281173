export interface Role {
  id: RoleId;
  name: string;
}

export enum RoleId {
  SUPERADMIN = 1,
  ADMIN,
  ORDER_MANAGER,
  CUSTOMER_SERVICE,
  PRINT_MANAGER,
  SCATTERER,
  SORTER,
  SHIPPING_MANAGER,
  INHOUSE_DESIGNER,
  OUTHOUSE_DESIGNER,
  CUSTOMER_SERVICE_MANAGER,
  CROPPER,
  OUTHOUSE_REVIEWER,
  MID_TIER_DESIGNER,
  ORDER_ASSISTANT,
  HR,
  STICKER_DESIGNER,
  AMAZON_UPLOADER,
  BOOTH_DESIGNER,
  LABELER,
  SHIPPINGEASY_UPLOADER,
  RETAIL_MANAGER,
  BULK_DESIGNER,
  SUBLIMATION_DESIGNER,
  SUBLIMATION_REVIEWER,
  STICKER_REVIEWER,
  SHIPPER,
  FREE_SAMPLE_APPROVER,
  FREE_SAMPLE_DESIGNER,
  SYSTEM = 999
}

export const AvailableRoles: Role[] = [
  {
    id: RoleId.SUPERADMIN,
    name: 'Superadmin'
  },
  {
    id: RoleId.ADMIN,
    name: 'Admin'
  },
  {
    id: RoleId.ORDER_MANAGER,
    name: 'Order Manager'
  },
  {
    id: RoleId.CUSTOMER_SERVICE,
    name: 'Customer Service'
  },
  {
    id: RoleId.PRINT_MANAGER,
    name: 'Print Manager'
  },
  {
    id: RoleId.SCATTERER,
    name: 'Scatman'
  },
  {
    id: RoleId.SORTER,
    name: 'Sorter'
  },
  {
    id: RoleId.SHIPPING_MANAGER,
    name: 'Shipping Manager'
  },
  {
    id: RoleId.INHOUSE_DESIGNER,
    name: 'Designer'
  },
  {
    id: RoleId.OUTHOUSE_DESIGNER,
    name: 'Outhouse Designer'
  },
  {
    id: RoleId.CUSTOMER_SERVICE_MANAGER,
    name: 'Customer Service Manager'
  },
  {
    id: RoleId.CROPPER,
    name: 'Cropper'
  },
  {
    id: RoleId.OUTHOUSE_REVIEWER,
    name: 'Outhouse Reviewer'
  },
  {
    id: RoleId.SYSTEM,
    name: 'System'
  },
  {
    id: RoleId.MID_TIER_DESIGNER,
    name: 'Mid-tier Designer'
  },
  {
    id: RoleId.ORDER_ASSISTANT,
    name: 'Order Assistant'
  },
  {
    id: RoleId.HR,
    name: 'HR'
  },
  {
    id: RoleId.STICKER_DESIGNER,
    name: 'Sticker Designer'
  },
  {
    id: RoleId.AMAZON_UPLOADER,
    name: 'Amazon Uploader'
  },
  {
    id: RoleId.BOOTH_DESIGNER,
    name: 'Booth Designer'
  },
  {
    id: RoleId.LABELER,
    name: 'Labeler'
  },
  {
    id: RoleId.SHIPPINGEASY_UPLOADER,
    name: 'ShippingEasy Uploader'
  },
  {
    id: RoleId.RETAIL_MANAGER,
    name: 'Retail Manager'
  },
  {
    id: RoleId.BULK_DESIGNER,
    name: 'Bulk Designer'
  },
  {
    id: RoleId.SUBLIMATION_DESIGNER,
    name: 'Sublimation Designer'
  },
  {
    id: RoleId.SUBLIMATION_REVIEWER,
    name: 'Sublimation Reviewer'
  },
  {
    id: RoleId.STICKER_REVIEWER,
    name: 'Sticker Reviewer'
  },
  {
    id: RoleId.SHIPPER,
    name: 'Shipper'
  },
  {
    id: RoleId.FREE_SAMPLE_APPROVER,
    name: 'Free Sample Approver'
  },
  {
    id: RoleId.FREE_SAMPLE_DESIGNER,
    name: 'Free Sample Designer'
  }
];
